import styled from 'styled-components'

const SubText = styled.p`
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  color: ${props => props.theme.core.content.tertiary};
  margin-bottom: 1.875rem;
`

export default SubText
