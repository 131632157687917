import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/seo'
import Footer from '../components/footer'
import HeaderText from '../components/headerText'
import SubText from '../components/subText'
import Box from '../components/box'
import Nav from '../components/nav'
import Typography from '../components/typography'
import Popper from '../components/hoverPopper'
import PricingCheck from '../icons/pricingCheck'
import PricingCheckGrid from '../icons/pricingCheckGrid'

import checkUtmParams from '../utils/checkUtmParams'
import config from '../utils/config'
import useHotjarScript from '../utils/hooks/useHotjarScript'
import { Card, InnerSection, Section } from '.'
import Button from '../components/button'
import { CheckMarkIcon } from '../icons'
import FaqSection from '../components/faqSection'
import CloudAcademySection from '../components/cloudAcademySection'
import Modal from '../components/modal'
import BookCallModal from '../components/bookCallModal'
import useClearbitReveal from '../utils/hooks/useClearbitReveal'

const Pricing = () => {
  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  const data = useStaticQuery(
    graphql`
      query {
        purpleGradientBg: file(
          relativePath: { eq: "1.3/purple-gradient-bg.png" }
        ) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const baseCardPerks = [
    'Up to 200 users',
    'Unlimited Streams',
    'Unlimited posts creation',
    'Up to 500GB of videos storage',
    'Unlimited transcripts generation',
    'Zoom, Slack, Discord and 10+ integrations'
  ]

  const proCardPerks = [
    'Everything in Base plan',
    'Unlimited video storage',
    'Content analytics (Posts and Videos)',
    'Private streams',
    'SAML SSO (Google SSO, Okta, and more)',
    'SCIM Provisioning'
  ]

  const enterpriseCardPerks = [
    'Everything in Pro plan',
    'White-glove onboarding & internal training',
    'Dedicated Account Executive',
    'Data retention policies',
    'Custom SAML SSO',
    'Custom TOS'
  ]

  const coreFeaturesMap = {
    'Workspace size': {
      description: 'Users part of the workspace',
      base: 'Max 200 users',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Searchable content': {
      description: 'Search the most recent content',
      base: <a className='underline text-gray-900 text-base tabletLToLower:text-sm leading-20'>Limited</a>,
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Post creation': {
      description: 'Written updates',
      base: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Video storage': {
      description: 'Content with videos',
      base: '500GB',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Video transcripts': {
      description: 'Generated for each video',
      base: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Embedded media': {
      description: 'Content with videos',
      base: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    'Backlinks': {
      description: 'Keep track of references to posts',
      base: true,
      pro: true,
      enterprise: true
    },
    'Templates': {
      description: 'Personal or workspace-wide',
      base: true,
      pro: true,
      enterprise: true
    },
    'People Profiles': {
      description: 'Custom-built profile for each users',
      base: true,
      pro: true,
      enterprise: true
    },
    'People Directory': {
      description: 'Unified and searchable view of all users',
      base: true,
      pro: true,
      enterprise: true
    },
    'Email notifications': {
      description: 'Receive emails about new workspace activities',
      base: true,
      pro: true,
      enterprise: true
    },
    'Post analytics': {
      description: 'See who saw what',
      base: false,
      pro: true,
      enterprise: true
    },
    'Video analytics': {
      description: 'Coming soon',
      base: false,
      pro: true,
      enterprise: true
    },
    'Stream-wide analytics': {
      description: 'Coming soon',
      base: false,
      pro: true,
      enterprise: true
    },
    'Private streams': {
      description: 'To collaborate privately on topics',
      base: false,
      pro: true,
      enterprise: true
    },
    'Workspace analytics': {
      description: 'Coming soon',
      base: false,
      pro: false,
      enterprise: true
    },
  }

  const integrationsMap = {
    'Email integration': {
      description: 'Email notifications on new activities',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Slack integration': {
      description: 'Notify Slack channels on new posts or videos',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Discord integration': {
      description: 'Notify Discord channels on new posts or videos',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Zoom integration': {
      description: 'Import and sync Zoom recorded meetings',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Google Meet integration': {
      description: 'Import and sync GMeet recorded meetings',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Google Drive integrations': {
      description: 'Import videos from your Google Drive account',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Loom integration': {
      description: 'Import videos from your Loom account',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Dropbox integration': {
      description: 'Import videos from your Dropbox account',
      base: true,
      pro: true,
      enterprise: true,
    },
    'Okta integration': {
      description: 'Allowing your team to login via SAML SSO',
      base: false,
      pro: true,
      enterprise: true,
    },
    'Zapier integration': {
      description: 'Coming soon',
      base: false,
      pro: true,
      enterprise: true,
    },
    'APIs': {
      description: 'Coming soon',
      base: false,
      pro: true,
      enterprise: true,
    },
  }

  const administrativeFeaturesMap = {
    'SAML SSO Login': {
      description: 'Manage access via SAML SSO',
      base: false,
      pro: true,
      enterprise: true,
    },
    'Google SSO Login': {
      description: 'Manage access via Google SSO',
      base: false,
      pro: true,
      enterprise: true,
    },
    'SCIM Provisioning': {
      description: 'Automatically provision users',
      base: false,
      pro: true,
      enterprise: true,
    },
    'Guest users': {
      description: 'Coming soon',
      base: false,
      pro: true,
      enterprise: true,
    },
    'Custom SAML SSO': {
      description: 'Support to custom-built SAML SSO',
      base: false,
      pro: false,
      enterprise: true,
    },
    'Two-factor authentication': {
      description: 'Coming soon',
      base: false,
      pro: false,
      enterprise: true,
    }
  }

  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  useClearbitReveal('/pricing')

  return (
    <>
      <SEO
        title="Pricing - flexible tiers for teams of all sizes"
        description="A base plan: the essentials for small autonomous teams. Advanced: pro features to pro teams. Enterprises: fully customizable options to meet your specific needs."
      />
      <Nav />
      <BookCallModal
        open={isBookCallModalOpen}
        onClose={() => {
          setIsBookCallModalOpen(false)
        }}
      />
      <Section>
        <InnerSection className='pt-48 mt-64'>
          <div className='text-center w-full text-5xl leading-52 font-display'>
            Pricing
          </div>
          <div className='mt-16 text-gray-foreground text-xl leading-24 text-center w-full'>
            14-days free trial, no credit card required.
          </div>
          <div className='mt-48 grid grid-cols-3 gap-24 tabletLToLower:grid-cols-1'>

            <div className='font-display'>
              <Card>
                <div className='leading-36 text-3xl'>
                  Base
                </div>
                <div className='mt-8 text-base leading-20 text-gray-foreground'>
                  Perfect for small distributed startups
                </div>
                <div className='mt-24 flex'>
                  <span className='text-4xl leading-[42px]'>$5</span>
                  <div className='flex flex-col justify-end'>
                    <div className='flex'>
                      <span className='text-xl leading-26 text-gray-foreground pt-2'>/</span>
                      <Popper
                        anchor={
                          <span className='text-xl leading-26 text-gray-foreground'
                            style={{
                              textDecoration: 'underline',
                              textDecorationStyle: 'dotted'
                            }}
                          >mo</span>
                        }
                        children={
                          <>
                            <Typography textType="paragraph-small">
                              Billed annually or $7 month-to-month
                            </Typography>
                          </>
                        }
                      />
                      <span className='text-xl leading-26 text-gray-foreground pt-2'>*</span>
                    </div>
                  </div>
                </div>
                <Button className='mt-24 w-full'>
                  Get started for free
                </Button>
                <div className='mt-24 grid place-content-center'>
                  <a className='w-full text-gray-foreground-400 no-underline text-sm leading-17'>Try it yourself today.</a>
                </div>
              </Card>
              <div className='pt-8 text-sm leading-17'>
                {baseCardPerks.map((perk, index) => (
                  <div className='flex mt-8' key={index}>
                    <div className='grid place-content-center h-full'>
                      <CheckMarkIcon />
                    </div>
                    <div className='ml-8 align-middle grid place-content-center'>
                      {perk}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='font-display'>
              <Card
                style={{
                  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${data.purpleGradientBg.childImageSharp.fixed.src})`,
                  backgroundPosition: 'center'
                }}
              >
                <div className='leading-36 text-3xl'>
                  Pro
                </div>
                <div className='mt-8 text-base leading-20' style={{
                  color: `rgba(0, 0, 0, 0.8)`
                }}>
                  Ideal for growth-stage distributed companies
                </div>
                <div className='mt-24 flex'>
                  <span className='text-4xl leading-[42px]'>$10</span>
                  <div className='flex flex-col justify-end'>
                    <div className='flex'>
                      <span className='text-xl leading-26 text-gray-foreground pt-2'>/</span>
                      <Popper
                        anchor={
                          <span className='text-xl leading-26 text-gray-foreground'
                            style={{
                              textDecoration: 'underline',
                              textDecorationStyle: 'dotted'
                            }}
                          >mo</span>
                        }
                        children={
                          <>
                            <Typography textType="paragraph-small">
                              Billed annually or $12 month-to-month
                            </Typography>
                          </>
                        }
                      />
                      <span className='text-xl leading-26 text-gray-foreground pt-2'>*</span>
                    </div>
                  </div>
                </div>
                <Button className='mt-24 w-full'>
                  Get started for free
                </Button>
                <div className='mt-24 grid place-content-center'>
                  <a className='w-full no-underline text-sm leading-17' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Try it for 14 days. No cc required</a>
                </div>
              </Card>
              <div className='pt-8 text-sm leading-17'>
                {proCardPerks.map((perk, index) => (
                  <div className='flex mt-8' key={index}>
                    <div className='grid place-content-center h-full'>
                      <CheckMarkIcon />
                    </div>
                    <div className='ml-8 align-middle grid place-content-center'>
                      {perk}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='font-display'>
              <Card>
                <div className='leading-36 text-3xl'>
                  Enterprise
                </div>
                <div className='mt-8 text-base leading-20 text-gray-foreground'>
                  Best suited for large-scale businesses
                </div>
                <div className='mt-24'>
                  <span className='text-4xl leading-[42px]'>{`Let’s chat. 🗣`}</span>
                </div>
                <Button className='mt-24 w-full' onClick={() => setIsBookCallModalOpen(true)}>
                  Contact us
                </Button>
                <div className='mt-24 flex justify-center w-full text-gray-foreground-400 text-sm leading-17'>
                  Or skip the line and {'  '}<a className='underline text-gray-foreground-400 text-sm leading-17 pl-4' href='https://cal.com/leonardofed/onboarding-call'
                    target="_blank"
                    rel="noopener noreferrer"
                  >book a demo</a>.
                </div>
              </Card>
              <div className='pt-8 text-sm leading-17'>
                {enterpriseCardPerks.map((perk, index) => (
                  <div className='flex mt-8' key={index}>
                    <div className='grid place-content-center h-full'>
                      <CheckMarkIcon />
                    </div>
                    <div className='ml-8 align-middle grid place-content-center'>
                      {perk}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </InnerSection>
      </Section>
      <Section>
        <InnerSection className='py-[96px]'>
          <FeaturesTableContainer>
            <div className='grid grid-cols-4 px-12 py-16 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display'>
              <div>
                Core Features
              </div>
              <div>
                Base
              </div>
              <div>
                Pro
              </div>
              <div>
                Enterprise
              </div>
            </div>
            {Object.keys(coreFeaturesMap).map((featureName, index) => {
              const featureRow = coreFeaturesMap[featureName]
              return (
                <div className='grid grid-cols-4 px-12 py-8 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display' style={{ borderTop: '1px solid #EEEEEE' }}>
                  <div>
                    <div>
                      {featureName}
                    </div>
                    <div className='text-sm leading-17 text-gray-foreground'>
                      {featureRow.description}
                    </div>
                  </div>
                  <div>
                    {featureRow.base === true ? <CheckMarkIcon /> : featureRow.base}
                  </div>
                  <div>
                    {featureRow.pro === true ? <CheckMarkIcon /> : featureRow.pro}
                  </div>
                  <div>
                    {featureRow.enterprise === true ? <CheckMarkIcon /> : featureRow.enterprise}
                  </div>
                </div>
              )
            })}
          </FeaturesTableContainer>
          <FeaturesTableContainer className='mt-16'>
            <div className='grid grid-cols-4 px-12 py-16 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display'>
              <div>
                Integrations
              </div>
              <div>
                Base
              </div>
              <div>
                Pro
              </div>
              <div>
                Enterprise
              </div>
            </div>
            {Object.keys(integrationsMap).map((featureName, index) => {
              const featureRow = integrationsMap[featureName]
              return (
                <div className='grid grid-cols-4 px-12 py-8 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display' style={{ borderTop: '1px solid #EEEEEE' }}>
                  <div>
                    <div>
                      {featureName}
                    </div>
                    <div className='text-sm leading-17 text-gray-foreground'>
                      {featureRow.description}
                    </div>
                  </div>
                  <div>
                    {featureRow.base === true ? <CheckMarkIcon /> : featureRow.base}
                  </div>
                  <div>
                    {featureRow.pro === true ? <CheckMarkIcon /> : featureRow.pro}
                  </div>
                  <div>
                    {featureRow.enterprise === true ? <CheckMarkIcon /> : featureRow.enterprise}
                  </div>
                </div>
              )
            })}
          </FeaturesTableContainer>
          <FeaturesTableContainer className='mt-16'>
            <div className='grid grid-cols-4 px-12 py-16 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display'>
              <div>
                Administrative Features
              </div>
              <div>
                Base
              </div>
              <div>
                Pro
              </div>
              <div>
                Enterprise
              </div>
            </div>
            {Object.keys(administrativeFeaturesMap).map((featureName, index) => {
              const featureRow = administrativeFeaturesMap[featureName]
              return (
                <div className='grid grid-cols-4 px-12 py-8 text-base tabletLToLower:text-sm gap-12 leading-20 text-gray-foreground-900 font-display' style={{ borderTop: '1px solid #EEEEEE' }}>
                  <div>
                    <div>
                      {featureName}
                    </div>
                    <div className='text-sm leading-17 text-gray-foreground'>
                      {featureRow.description}
                    </div>
                  </div>
                  <div>
                    {featureRow.base === true ? <CheckMarkIcon /> : featureRow.base}
                  </div>
                  <div>
                    {featureRow.pro === true ? <CheckMarkIcon /> : featureRow.pro}
                  </div>
                  <div>
                    {featureRow.enterprise === true ? <CheckMarkIcon /> : featureRow.enterprise}
                  </div>
                </div>
              )
            })}
          </FeaturesTableContainer>
        </InnerSection>
      </Section>
      <FaqSection />
      <CloudAcademySection />
      <Footer />
    </>
  )
}

const FeaturesTableContainer = styled.div`
  background: #FFFFFF;
  /* Foreground/Gray 200 */

  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`

const Container = styled.div`
  #pricing-levels-grid {
    display: none;
    margin-top: 7rem;
  }

  #sticky-pricing-header {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: ${props => props.theme.spacing(8)};
  }

  @media (min-width: 37.5rem) {
    #pricing-levels-grid {
      display: block;
    }
  }
`

const PricingLevels = styled.div`
  display: flex;
  margin: auto;
  width: 68.75rem;
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 45.9375rem) {
    flex-direction: row;
  }
`

const Level = styled.div`
  width: 20rem;
  align-self: center;
  justify-content: center;
  max-width: 100%;
  max-height: max-content;
  background: #f6f6f6;
  border-top: 0.4375rem solid ${props => props.color};
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;

  .price {
    padding-top: 2.5rem;
    padding-left: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .label {
    padding: 1.5rem;
    box-sizing: border-box;
    background: #eeeeee;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${props => props.theme.core.content.secondary};

    b {
      color: #000;
    }
  }

  .lower {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1.5rem;
      display: flex;
      font-size: 0.875rem;
      line-height: 1.25rem;

      svg {
        margin-right: 0.625rem;
      }
    }
  }
`

const LevelsGrid = styled.div`
  display: flex;
  flex-direction: column;
`

const GridRow = styled.div`
  display: grid;
  margin: auto;
  width: 68.75rem;
  max-width: 100vw;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
  grid-template-columns: 20rem 13.4375rem 1.875rem 13.4375rem 1.875rem 13.4375rem;
  grid-template-rows: ${props =>
    `repeat(${props.rows || 1}, minmax(3.25rem, auto))`};
  grid-template-areas: 'label base spacer advanced spacer enterprise';

  ${props =>
    !props.disableHover &&
    css`
      :hover {
        .base,
        .advanced,
        .enterprise,
        .label,
        .spacer {
          background: rgba(196, 196, 196, 0.5);
        }
      }
    `}

  .title {
    font-family: ${props => props.theme.typography.fontFamilyGTBold};
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375rem;
    text-transform: uppercase;
    color: #000000;
  }

  .header {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.0938rem;
    text-transform: uppercase;
    color: #000000;
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }

  .base,
  .advanced,
  .enterprise {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #f6f6f6;
  }

  .dotted-underline {
    border-bottom: 0.0938rem dashed #000000;
  }
`

const CTAButton = styled(Link)`
  padding: 1rem 4.6875rem;
  background: #000000;
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
`

const H2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  color: #000000;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  .subtext {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    color: ${props => props.theme.core.content.tertiary};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .dotted-underline {
    border-bottom: 0.0938rem dashed #000000;
  }
`

const Bold = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
`

export default Pricing
